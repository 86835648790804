import React, {useEffect, useState} from 'react';
import useScrollY from "../hooks/useScrollY";

function Reveal({children, darkMode, bgColor}) {
    const [scrollDirection, setScrollDirection] = useState(null);
    const scrollY = useScrollY();
    const [prevScrollY, setPrevScrollY] = useState(scrollY);
    useEffect(() => {
        if (scrollY > prevScrollY) {
            setScrollDirection('down');
        } else if (scrollY < prevScrollY) {
            setScrollDirection('up');
        }
        setPrevScrollY(scrollY);
    }, [scrollY]);

    let backgroundColor = darkMode ? '#24282f' : bgColor || '#152691';
    if (prevScrollY < 200) {
        backgroundColor = 'transparent';
    }


    return (
        <div style={{
            backgroundColor,
            position: 'fixed',
            top: 0,
            width:'100%',
            transition: 'all 0.3s',
            transform: `translateY(${scrollDirection === 'up' || prevScrollY < 50 ? 0 : -100}%)`,

        }}
        >

            {children}
            <div style={{
                transition: 'all 0.3s',
                height: 3,
                backgroundColor: prevScrollY > 200 && darkMode ? '#555657' : 'transparent',
            }}></div>
        </div>
    );
}

export default Reveal;