import {EmploymentTypes} from "../../../../../../../models";
import {capitaliseFirst} from "../../../../assets/functions/functions";
import questionProps from "../questionProps";
import { nonEuroCurrency} from "./income";
import {publicSector} from "../../setup/affordability-evaluation/applicant-evaluation/employment/questionsSetupEmployment";
import {isInPast, isValidPhone, monthYearFormat} from "../../../../../../app/data/validators";
import {correctValueForDb} from "../../../../data-source/providers/MortgageProvider";
import {differenceInYears} from "date-fns";
import dayjs from "dayjs";

export function employmentType(props) {
    return {
        label: "Employment Status",
        text: "What is " + props.you.your + " current employment status?",
        name: "employmentType",
        answer: {
            type: "select",
            choices: [
                {
                    text: "Employed",
                    value: "EMPLOYED"
                },
                {
                    text: "Self-Employed",
                    value: "SELFEMPLOYED"
                },
                {
                    text: "Homemaker",
                    value: "HOMEMAKER"
                },
                {
                    text: "Student",
                    value: "STUDENT"
                }
            ]
        },
        model: "employment",
        rules: [
            {required: true, message: "A selection is required"},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].employmentType`,
        saveOnChange: true
    }
}

export function employmentBasis(props) {
    return {
        text: "On what basis " + props.you.areYou + " employed?",
        label: "Employment Basis",
        name: "employmentBasis",
        answer: {
            type: "select",
            choices: [
                {
                    text: "Full-time",
                    value: "FULLTIME"
                },
                {
                    text: "Contract Basis",
                    value: "CONTRACT"

                },
                {
                    text: "Temporary Full-time",
                    value: "TEMPFULLTIME"
                },
                {
                    text: "Part-time",
                    value: "PARTTIME"
                },
                {
                    text: "Temporary Part-time",
                    value: "TEMPPARTTIME"
                },
            ]
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: "A selection is required"},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].employmentBasis`
    }
}
export function currentEmployerName(props) {
    return {
        label: "Employer",
        text: "With whom " + props.you.areYou + " currently employed?",
        name: "currentEmployerName",
        answer: {
            type: "text"
        },
        model: "employment",
        rules: [
            {required: true, message: "A company name is required"},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].currentEmployerName`,
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
    }
}
export function currentEmployerAddress(props) {
    return {
        label: "Employer Address",
        text: "What is " + props.you.your + " employer's address?",
        name: "currentEmployerAddress",
        answer: {
            type: "address"
        },
        model: "employment",
        rules: [
            {required: true, message: "An address is required"},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].currentEmployerAddress`,
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
    }
}
export function currentEmployerStartDate(props) {
    //TODO: change to MMYYYY
    return {
        label: "Employment Start Date",
        text: "When did " + props.you.you + " start employment there",
        name: "currentEmployerStartDate",
        answer: {
            type: "ddmmyyyy",
        },
        model: "employment",
        rules: [
            {required: true, message: `A date is required`},
            {validator: isInPast}
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].currentEmployerStartDate`,
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
    }
}
export function currentEmployerPhone(props) {
    return {
        text: "What is " + props.you.your + " employer's contact number?",
        name: "currentEmployerPhone",
        label: "Phone Number",
        answer: {
            type: "phone"
        },
        model: "employment",
        rules: [
            {required: true, message: `A phone number is required`},
            {validator: isValidPhone}
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].currentEmployerPhone`,
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
    }
}
export function positionHeld(props) {
    return {
        name: "positionHeld",
        label: "Position",
        text: "What is " + props.you.your + " position there?",
        answer: {
            type: "text"
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.EMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: "A job title is required"},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].positionHeld`
    }
}
export function firstJob(props) {
    return {
        text: "Is this your first job?",
        label: "First Job",
        name: "firstJob",
        answer: {
            type: "choice",
            choices: [
                {value: true, text: "Yes"},
                {value: false, text: "No"}
            ]
        },
        hides: (form)=>{
            let dateToTest = null

            if (form.getFieldValue('employmentType') === EmploymentTypes.EMPLOYED) {
                let currentEmployerStartDate = form.getFieldValue('currentEmployerStartDate')

                if (currentEmployerStartDate) {
                    dateToTest = dayjs(correctValueForDb('ddmmyyyy', currentEmployerStartDate), 'YYYY-MM-DD', true)
                }
            }
            if (form.getFieldValue('employmentType') === EmploymentTypes.SELFEMPLOYED) {
                let businessEstablished = form.getFieldValue('businessEstablished')
                if (businessEstablished) {
                    dateToTest = dayjs(correctValueForDb('ddmmyyyy', businessEstablished), 'YYYY-MM-DD', true)
                    //   dateToTest = correctValueForDb('mmyyyy', businessEstablished)
                }
            }

            if (dateToTest) {
                let w = dayjs().diff(dateToTest, 'years')

                // differenceInYears(new Date(), new Date(dateToTest))
                return !(w < 3)
            }
            return true
        },
        model: "employment",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].firstJob`
    }
}
const prevEmployerRequired = (form)=>{
    let dateToTest = null

    if (form.getFieldValue('employmentType') === EmploymentTypes.EMPLOYED) {
        let currentEmployerStartDate = form.getFieldValue('currentEmployerStartDate')
        if (currentEmployerStartDate) {
            dateToTest = dayjs(correctValueForDb('ddmmyyyy', currentEmployerStartDate), 'YYYY-MM-DD', true)
            //correctValueForDb('ddmmyyyy', currentEmployerStartDate)
        }
    }
    if (form.getFieldValue('employmentType') === EmploymentTypes.SELFEMPLOYED) {
        let businessEstablished = form.getFieldValue('businessEstablished')
        if (businessEstablished) {
            dateToTest = dayjs(correctValueForDb('ddmmyyyy', businessEstablished), 'YYYY-MM-DD', true)
         //   dateToTest = correctValueForDb('ddmmyyyy', businessEstablished)
        }
    }
    if (dateToTest && form.getFieldValue('firstJob') === true) {
        return false
    }


    if (dateToTest  ) {
        let w = dayjs().diff(dateToTest, 'years')
        // differenceInYears(new Date(), new Date(dateToTest))
        return w < 3
    }
    return false
}

export function previousEmployerName(props) {
    return {
        label: "Previous Employer",
        text: "With whom " + props.you.wereYou + " previously employed?",
        name: "previousEmployerName",
        answer: {
            type: "text"
        },
        hides: (form) => !prevEmployerRequired(form),
        model: "employment",
        rules: [
            {required: true, message: `A company name is required`},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].previousEmployerName`
    }
}
export function previousEmployerAddress(props) {
    return {
        label: "Previous Employer Address",
        text: "What was " + props.you.your + " previous employer's address?",
        name: "previousEmployerAddress",
        answer: {
            type: "address"
        },
        hides: (form) => !prevEmployerRequired(form),
        model: "employment",
        rules: [
            {required: true, message: `An address is required`},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].previousEmployerAddress`
    }
}
export function previousPositionHeld(props) {
    return {
        name: "previousPositionHeld",
        label: "Previous Position Held",
        text: "What was " + props.you.your + " position there?",
        answer: {
            type: "text"
        },
        hides: (form) => !prevEmployerRequired(form),
        model: "employment",
        rules: [
            {required: true, message: "A job title is required"},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].previousPositionHeld`
    }
}
export function previousEmploymentBasis(props) {
    return {
        text: "On what basis " + props.you.wereYou + " employed there?",
        label: "Previous Employment Basis",
        name: "previousEmploymentBasis",
        answer: {
            type: "select",
            choices: [
                {
                    text: "Full-time",
                    value: "FULLTIME"
                },
                {
                    text: "Contract Basis",
                    value: "CONTRACT"

                },
                {
                    text: "Temporary Full-time",
                    value: "TEMPFULLTIME"
                },
                {
                    text: "Part-time",
                    value: "PARTTIME"
                },
                {
                    text: "Temporary Part-time",
                    value: "TEMPPARTTIME"
                },
            ]
        },
        hides: (form) => !prevEmployerRequired(form),
        model: "employment",
        rules: [
            {required: true, message: "A selection is required"},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].previousEmploymentBasis`
    }
}
export function previousEmployerStartDate(props) {
    //TODO: change to MMYYYY
    return {
        label: "Previous Employment Start Date",
        text: "When did " + props.you.you + " start employment there",
        name: "previousEmployerStartDate",
        answer: {
            type: "ddmmyyyy",
        },
        model: "employment",
        rules: [
            {required: true, message: `A date is required`},
            {validator: isInPast}
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].previousEmployerStartDate`,
        hides: (form) => !prevEmployerRequired(form),
    }
}
export function previousEmployerEndDate(props) {
    //TODO: change to MMYYYY
    return {
        label: "Previous Employment End Date",
        text: "When did " + props.you.you + " end employment there",
        name: "previousEmployerEndDate",
        answer: {
            type: "ddmmyyyy",
        },
        model: "employment",
        rules: [
            {required: true, message: `A date is required`},
            {validator: isInPast}
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].previousEmployerEndDate`,
        hides: (form) => !prevEmployerRequired(form),
    }
}




export function limitedCompany(props) {
    return {
        text: "Is " + props.you.your + " business a limited company?",
        label: "Limited Company",
        name: "limitedCompany",
        answer: {
            type: "choice",
            choices: [
                {value: true, text: "Yes"},
                {value: false, text: "No"}
            ]
        },
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
        model: "employment",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].limitedCompany`
    }
}
export function isDirector(props) {
    return {
        text: capitaliseFirst(props.you.areYou) + " a director or shareholder in the company?",
        label: "Director/Shareholder",
        name: "isDirector",
        answer: {
            type: "choice",
            choices: [
                {value: true, text: "Yes"},
                {value: false, text: "No"}
            ]
        },
        hides: (form) => {
            if (form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED) {
                return true
            }
            return form.getFieldValue('limitedCompany') === false;
        },
        model: "employment",
        rules: [
            {required: true, message: `A selection is required`},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].isDirector`
    }
}
export function percentageOfShares(props) {
    return {
        label: "Percentage Of Shares",
        text: "What percentage of shares " + props.you.doYou + " hold in the company?",
        name: "percentageOfShares",
        answer: {
            type: "float"
        },
        hides: (form) => {
            if (form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED) {
                return true
            }
            if (form.getFieldValue('limitedCompany') === false) {
                return true
            }
            return form.getFieldValue('isDirector') === false;
        },
        model: "employment",
        rules: [
            {required: true, message: `A percentage is required`},
            {type: 'number', min: 1, message: 'This must be a value greater than zero'},
            {type: 'number', max: 100, message: 'This must be a value maximum 100'},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].percentageOfShares`
    }
}
export function accountantName(props) {
    return {
        label: "Accountant",
        text: "Who is " + props.you.your + " accountant?",
        name: "accountantName",
        answer: {
            type: "text"
        },
        model: "employment",
        rules: [
            {required: true, message: `An accountant name is required`}
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].accountantName`,
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
    }
}
export function accountantAddress(props) {
    return {
        label: "Accountant Address",
        text: "What is " + props.you.your + " accountant's address?",
        name: "accountantAddress",
        answer: {
            type: "address"
        },
        model: "employment",
        rules: [
            {required: true, message: `An address is required`}
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].accountantAddress`,
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
    }
}
export function accountantPhone(props) {
    return {
        label: "Accountant's Phone",
        text: "What is their phone number?",
        name: "accountantPhone",
        answer: {
            type: "phone"
        },
        model: "employment",
        rules: [
            {required: true, message: `A phone number is required`},
            {validator: isValidPhone}
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].accountantPhone`,
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
    }
}
export function nameOfBusiness(props) {
    return {
        label: "Business Name",
        text: "What is the business's trading name?",
        name: "nameOfBusiness",
        answer: {
            type: "text"
        },
        model: "employment",
        rules: [
            {required: true, message: `A business name is required`},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].nameOfBusiness`,
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
    }
}
export function natureOfBusiness(props) {
    return {
        label: "Nature of Business",
        text: "What is the nature of " + props.you.your + " business?",
        name: "natureOfBusiness",
        answer: {
            type: "text"
        },
        model: "employment",
        rules: [
            {required: true, message: `A description is required`},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].natureOfBusiness`,
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
    }
}
export function businessAddress(props) {
    return {
        label: "Business Address",
        text: "What is the business's address?",
        name: "businessAddress",
        answer: {
            type: "address"
        },
        model: "employment",
        rules: [
            {required: true, message: `An address is required`},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].businessAddress`,
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },
    }
}
export function businessEstablished(props) {
    return {
        label: "Business Established",
        text: "When was the business established?",
        name: "businessEstablished",
        answer: {
            type: "mmyyyy"
        },
        model: "employment",
        rules: [
            {required: true, message: `A date is required`},
            {validator: monthYearFormat},
            {validator: isInPast},
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].businessEstablished`,
        hides: (form) => {
            return form.getFieldValue('employmentType') !== EmploymentTypes.SELFEMPLOYED
        },

    }
}


export function grossBasic(props) {
    return {
        label: "Gross Basic Pay",
        text: "What is " + props.you.your + " gross basic yearly salary? (before tax)",
        name: "grossBasic",
        answer: {
            type: "euro",
        },
        model: "income",
        rules: [
            {required: true, message: `An basic pay amount is required`},
            {type: 'number', min: 1, message: 'Must be greater than €1'},
            {type: 'number', max: 1000000, message: 'Must be less than €1,000,000'}
        ],
        target: `applicant${props.applicant}.employmentIncome[${props.index}].grossBasic`,
        hides: (form) => {
            return ![EmploymentTypes.SELFEMPLOYED,EmploymentTypes.EMPLOYED].includes(form.getFieldValue('employmentType'))
        },
    }
}
function employmentIncome(props) {
    const betterProps = questionProps(props)
    let eType = employmentType(betterProps)
    if (props.mortgage[`applicant${props.applicant}`].employmentIncome.length > 1) {
        eType.answer.choices = eType.answer.choices.filter(item => ['SELFEMPLOYED', 'EMPLOYED'].includes(item.value))
    }
    const questions = [
        eType,
        employmentBasis(betterProps),
        publicSector(betterProps),
        nonEuroCurrency(betterProps),

        nameOfBusiness(betterProps),
        natureOfBusiness(betterProps),
        businessAddress(betterProps),
        businessEstablished(betterProps),

        positionHeld(betterProps),
        limitedCompany(betterProps),

        accountantName(betterProps),
        accountantAddress(betterProps),
        accountantPhone(betterProps),
        isDirector(betterProps),
        percentageOfShares(betterProps),
        currentEmployerName(betterProps),
        currentEmployerAddress(betterProps),
        currentEmployerPhone(betterProps),
        currentEmployerStartDate(betterProps),

        firstJob(betterProps),
        previousEmployerName(betterProps),
        previousEmployerAddress(betterProps),
        previousEmploymentBasis(betterProps),
        previousPositionHeld(betterProps),
        previousEmployerStartDate(betterProps),
        previousEmployerEndDate(betterProps),
        grossBasic(betterProps),
    ]

    return questions
}

export default employmentIncome;