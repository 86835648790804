import React, {useContext, useEffect, useState} from 'react';
import {fileNameAndExtension, sanitizeFileName} from "../../assets/functions/functions";
import {SectionTypes, UploadStatuses} from "../../../../../models";
import {Storage} from "@aws-amplify/storage";
import {Mortgage} from "./MortgageProvider";
import {Auth} from "aws-amplify";
import AuthCheck, {AuthCheckContext} from "../../../../app/auth/AuthCheck";
import {App} from "antd";
import {v4} from "uuid";

export const S3 = React.createContext({})

function S3Provider(props) {
    const app = App.useApp()
    const [mortgageChecksDone, setMortgageChecksDone] = useState(false)
    const {user} = useContext(AuthCheckContext)
    const mortgage = useContext(Mortgage)
    const [identityId,setIdentityId] = useState(false)
    const setIdentity = async ()=>{
        const credentials = await Auth.currentUserCredentials();
        setIdentityId(credentials.identityId)
    }
    const doMortgageChecks = async ()=>{
        setMortgageChecksDone(true)
        if (!user.isAdmin){
            if (!mortgage.applicant1?.identityID && user.sub === mortgage.applicant1.subjectId){
                await mortgage.mutate({
                    update: {
                        target: `applicant1.identityID`,
                        value: identityId
                    }
                })
            }
            if (!mortgage.applicant2?.identityID && user.sub === mortgage.applicant2.subjectId){
                await mortgage.mutate({
                    update: {
                        target: `applicant2.identityID`,
                        value: identityId
                    }
                })
            }
        }
    }
    useEffect(()=>{
        setIdentity()
    },[])
    useEffect(()=>{
        if (!mortgageChecksDone && mortgage && !!identityId){
            doMortgageChecks()
        }
    }, [mortgage, identityId])
    /*
    
    
    
    Types of documents to be kept here are, from a portal POV
    a) mortgage
        - application uploads,
        - conditions stage uploads
        - No stamped copies of uploads!
        - user compliance folder documents
        
        * Mortgage Upload Definition
            contentType: String
            fileName: String
            fileSize: Int
            fileExtension: String
            stage: ApplicationStages
            section: String
            index: ID       
            status: UploadStatuses
            mortgageID: ID! @index(name: "byUserMortgage")
            observeMID: String
            reason: String
            identityID: String
            
        * Mortgage Document Definition
            id: ID!
            title: String
            description: String
            identityID: String
            folder: String
            fileName: String
            fileExtension: String
            isDynamic: Boolean
            mortgageID: ID! @index(name: "byUserMortgage")
            
            
     Params to receive       
            
     */

    const getUserDocumentPathAndConfig = ({identityID, folder, fileName, mortgageID}, downloadDontOpen) => {
        let config = {
            level: 'private',
            download: downloadDontOpen || false,
            contentType : 'application/pdf'
        }
        let ext = fileName.endsWith('.pdf') ? '' : '.pdf'
        if (user.isAdmin){
            config.identityId = identityID
        }
        let path =  `files/mortgage/${mortgageID}/documents/${folder}/${fileName}${ext}`
        return {path, config}
    }

    const getUserUploadKey = ({id, fileName, fileExtension, mortgageID, stage, section, index}) => {
        let path = `files/mortgage/${mortgageID}/uploads/${stage}/${section}/`
        if (index) {
            path += `${index}/`
        }
        path += `${id}/${fileName}.${fileExtension}`
        return path
    }

    const addToUserUploads = async ({
                                        stage,
                                        section,
                                        index,
                                        file,
                                        progressCallback,
                                        completeCallback,

                                        errorCallback
                                    }) => {
        const sanitized = sanitizeFileName(file.name)
        const {fileName, fileExtension} = fileNameAndExtension(sanitized)
        // ADD UPLOAD DETAILS TO UserMortgageUploads
        const match = section.match(/-(1|2)$/)
        const applicant = match ? parseInt(match[0].replace('-', '')) : false
        const readAccessGroup = applicant === 1 ? [`${mortgage.id}-app2`] : []
        const editAccessGroup = applicant === 1 ? [`${mortgage.id}-app1`] : [`${mortgage.id}-app2`, `${mortgage.id}-app2`]

        const pairs = {
            id: v4(),
            contentType: file.type,
            fileName,
            fileSize: file.size,
            fileExtension,
            stage,
            section,
            identityID: identityId,
            status: UploadStatuses.UPLOADING,
            mortgageID: mortgage.id,
            observeMID: mortgage.id,
            readAccessGroup,
            editAccessGroup
        }
        if (index) {
            pairs.index = index
        }
        await mortgage.upload.create(pairs)
        let newUploadRow = pairs
        //TODO: create a temporary upload until upload success handler calls
        // let newUploadResult = await mortgage.mutate({
        //     create: {
        //         target: `uploads`,
        //         pairs
        //     }
        // })


        //let newUploadRow = newUploadResult.create[0].pairs

        // ADD FILE TO S3
        const uuid = newUploadRow.id
        const config = {
            contentType: file.type,
            level: "private",
            resumable: true,
            progressCallback,
            completeCallback: (event) => {

                // turn the temp upload into a real one
                mortgage.upload.confirm(pairs)
                return completeCallback({...event, item: newUploadRow})
            },
            errorCallback: async (event) =>{
                //TODO: Remove the item from file list

                app.notification.error({
                    duration:0,
                    description: `Something went wrong with uploading ${newUploadRow.fileName}.${newUploadRow.fileExtension}`,
                    message: `Upload Error`
                })
                mortgage.upload.remove(pairs)
                return errorCallback(event)
                //return errorCallback({...event, item: newUploadRow})
            }
        }
        let path = getUserUploadKey(newUploadRow)
        try {
            const s3Result = await Storage.put(path, file, config);
        } catch (e) {
            console.log(e)
        }


        //return result.key; // Return the key or the S3 URL of the uploaded file
        return uuid

    }
    //https://sussd-user-files55436-staging.s3.eu-west-1.amazonaws.com/?list-type=2&prefix=private%2Feu-west-1%3Af602d686-7d2e-4bb8-b657-6465aa2226b5%2Ffiles%2Fmortgage%2F306e165c-93aa-474b-b203-2005d212c679%2Fuploads%2FAPPLY%2Faccounts-1%2Fbc4c2ade-5630-44ae-bae2-722b2e38b552%2FSales+Coordinator+Work+Flow.pdf
    const getUserUploadPathAndConfig = (upload, downloadDontOpen) => {
        let config = {
            level: 'private',
            download: downloadDontOpen || false,
            contentType : upload.contentType
        }
        if (user.isAdmin){
            config.identityId = upload.identityID
        }
        let path
        // modern uploads have a fileExtension attribute and identityID, and are stored in private folder

        if (upload.fileExtension) {
            path = getUserUploadKey(upload)
        }
        // legacy : User uploads, no file name in s3, in protected folder, must supply content type
        else {
            config.level = 'protected'

            path = `${upload.mortgageID}/${upload.section}/`
            if (upload?.index) {
                path += `${upload.index}/`
            }
            path += `${upload.id}`
        }
        return {path, config}

    }
    const getS3Url = async ({path, config}) => {

        const url = await Storage.get(path, config);
        return url
    }
    const deleteUserUpload = async (id) => {
        let upload = mortgage.uploads.find(upload => upload.id === id)
        let oldPathAndConfig = getUserUploadPathAndConfig(upload)
        let result1 = await Storage.remove(oldPathAndConfig.path, {level: oldPathAndConfig.config.level});

        let result2 = await mortgage.mutate({
            delete: {
                target: `uploads`,
                id: id
            }
        })
        return result2

    }
    const renameUserUpload = async (id, fileName) => {
        // only necessary in the public/files s3 folder (known because fileExtensiong didn't exist in legacy
        let v = sanitizeFileName(fileName)
        let oldUpload = mortgage.uploads.find(upload => upload.id === id)

        const update = {
            target: `uploads[${id}].fileName`,
            value: v,
        }
        // Only new uploads to change name in s3 (legacy items have no Filename in S3, so just the DB is updated)
        if (oldUpload.fileExtension) {
            const oldPathAndConfig = getUserUploadPathAndConfig(oldUpload)
            const newPathAndConfig = getUserUploadPathAndConfig({...oldUpload, fileName: v})
            await Storage.copy({key: oldPathAndConfig.path, level: oldPathAndConfig.config.level}, {
                key: newPathAndConfig.path,
                level: oldPathAndConfig.config.level
            });
            await Storage.remove(oldPathAndConfig.path, {level: oldPathAndConfig.config.level});
        }
        else{
            const { fileName, fileExtension } = fileNameAndExtension(oldUpload.fileName)
            if (fileExtension){
                update.value += `.${fileExtension}`
            }
        }
        let result = await mortgage.mutate({update})
        let name =  result.update[`mortgage.uploads[${id}]`].pairs.fileName
        if (oldUpload.fileExtension) {
            name += `.${oldUpload.fileExtension}`
        }
        return name
    }

    return (
        <S3.Provider value={{
            uploads: {
                add: addToUserUploads,
                get: getUserUploadPathAndConfig,
                delete: deleteUserUpload,
                rename: renameUserUpload,
            },
            documents: {
                get: getUserDocumentPathAndConfig,
            },
            getS3Url,
        }}>
            {props.children}
        </S3.Provider>
    );
}

export default S3Provider;