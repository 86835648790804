import React, {useContext, useEffect, useRef, useState} from 'react';
import {App, Button, Form, Input} from "antd";
import {EditOutlined, LoadingOutlined} from "@ant-design/icons";
import {Mortgage} from "../../../data-source/providers/MortgageProvider";
import {SectionTypes} from "../../../../../../models";
import PropTypes from "prop-types";

MortgageSectionNote.propTypes = {
    record: PropTypes.object.isRequired,
};
function MortgageSectionNote(props) {
    const mortgage = useContext(Mortgage)
    const textareaRef = useRef()
    const [saving, setSaving] = useState(false)
    const {modal} = App.useApp();
    const [form] = Form.useForm()
    let modalRef = null
    const handleWriteNoteClick = () => {
        modalRef = modal.confirm({
            title: "Leave us a note",
            content: <NoteText/>,
            icon: <EditOutlined/>,
            okText: "Save note",
            footer: null
        })
    }
    const handleSave = async () => {
        try {
            const valid = await form.validateFields()
            setSaving(true)
            await mortgage.mutate({
                update: {
                    target: `progress[${props.record.id}].note`,
                    value: valid.note.split('\n')
                }
            })
            setSaving(false)
            modalRef.destroy()

        } catch (e) {
            console.log(e)
        }
    }
    function NoteText() {
        useEffect(() => {
            if (textareaRef.current) {
                setTimeout(() => {
                    textareaRef.current.focus()
                }, 50)
            }
        }, [])
        const noteValue = !!props.record && !!props.record.note ? props.record.note.join('\n') : ''
        return (
            <Form form={form} initialValues={{note: noteValue}}>
                <Form.Item name="note" rules={[{required: true, message: 'A note is required'}]}>
                    <Input.TextArea rows={5} ref={textareaRef}/>
                </Form.Item>
                <div className="flex gap-3 justify-end mt-4">
                    <Button shape="round" onClick={modalRef.destroy}>Cancel</Button>
                    <Button shape="round" type="primary" onClick={handleSave}>
                        Save note

                        {saving && (<LoadingOutlined className="ms-3"/>)}
                    </Button>
                </div>
            </Form>

        )
    }
    return (
        <div className="flex flex-col items-center justify-center mt-4">
            <div style={{width: 300, textAlign: "center"}} className="mb-4">
                {props.children}
            </div>
            <Button onClick={handleWriteNoteClick} icon={<EditOutlined/>}> Leave us a note </Button>
        </div>
    );
}

export default MortgageSectionNote;