import React, {useContext} from 'react';
import useMortgage from "./useMortgage";
import {S3} from "../providers/S3Provider";

function useMortgageDocuments(props) {
    const {mortgage} = useMortgage()
    const s3 = useContext(S3)

    const mapper = it => {

        let onClick =  async ()=>{
            const {config,path} = s3.documents.get(it)

            const url = await s3.getS3Url({path,config})
            window.open(url, 'document', "height=900,width=750,toolbar=0,location=0,menubar=0");
        }
        return {
            key: it.id,
            title: it.title,
            description: it.fileName,
            icon: it.icon,
            onClick
        }
    }
    const lenderDocs = mortgage.UserMortgageDocuments.filter(it => it.folder === 'lender-documents').map(mapper)
    const sussdDocs = mortgage.UserMortgageDocuments.filter(it => it.folder === 'sussd-documents').map(mapper)
    return {lenderDocs,sussdDocs}
}

export default useMortgageDocuments;